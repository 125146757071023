@import "../../../globals.scss";

.ps-tabs {
  flex: 1;
  display: flex;
  border-bottom: 1px solid $gray;
}

.ps-tab {
  flex: 1;
  padding: 10px 20px;
  background-color: $gray;
  color: $placeholder;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.active {
    background-color: white;
    color: $blue;
  }
}

.ps-section {
  padding: 10px;
  border-radius: 5px;
  background-color: #bcb8b812;
  max-width: 830px;
}

@media only screen and (max-width: 800px) {
  .ps-tabs {
    flex-direction: column;
  }
  .ps-section {
    max-width: 400px;
  }
}

.psubtitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.time-to-ship {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 800px) {
  .time-to-ship {
    flex-direction: column;
  }
}

.weight-section {
  padding: 10px;
  background-color: $veryVeryLightBlue;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
