.custom-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.custom-field {
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 30px;
}

.custom-field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.delete-custom-field {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.custom-field-add {
  position: relative;
  margin-top: 10px;
}

.add-custom-field-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
}

.custom-field-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.custom-field-option {
  display: block;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}
