@import "../../../globals.scss";

.role-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
}

.role-sidebar {
  display: flex;
  flex-direction: column;
}

.role-sidebar-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 15px;
  width: 200px;
  color: $optionFg;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  justify-content: space-between;

  &.active {
    background-color: $blue;
    color: $white;
  }
}

.role-right-side {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.perm-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.racc {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: small;
  background-color: #bcb8b830;
  border-radius: 0px;

  &.open {
    background-color: #bcb8b812;
  }

  .racc-head {
    display: flex;
    justify-content: space-between;
    // cursor: pointer;
    padding: 10px;
    border-radius: 0px;
  }
  .racc-head.open {
    background-color: #bcb8b830;
  }

  .racc-arrow {
    display: none;
    margin-left: auto;
    height: 20px;
    width: 20px;
    background-color: $white;
    padding: 5px;
    border-radius: 50%;
    transform: rotate(-90deg);
  }

  .racc-arrow.open {
    transform: rotate(90deg);
  }

  .racc-body {
    gap: 10px;
    overflow: hidden;
    padding: 0px 10px;
    max-height: 0px;
  }

  .racc-body.open {
    overflow: visible;
    padding: 18px;
    padding-top: 12px;
    max-height: 10000px;
  }
}
