@import "../../globals.scss";

.dashboard {
  width: 100%;
  height: 100%;

  .heading {
    .title {
      font-size: 30px;
      font-weight: 400;
    }
  }

  .dashboard-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    padding: 17px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    width: 200px;
    flex-grow: 1;

    .dashboard-card-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dashboard-card-title {
        font-weight: bold;
        font-size: 14px;
      }

      .dashboard-card-icon {
        height: 12px;
        width: 12px;
      }
    }

    .dashboard-card-body {
      font-weight: bold;
      font-size: 20px;
    }

    .dashboard-card-sub-body {
      font-weight: normal;
      font-size: 12px;
      color: $placeholderShown;
    }
  }

  .dashboard-placeholder-1 {
    padding: 17px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    min-height: 350px;
    width: 600px;
    overflow: auto;
    flex-grow: 1;
    color: black;
    font-size: x-large;
    font-weight: bold;
  }

  .dashboard-placeholder-2 {
    padding: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    width: 300px;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 400px;
  }

  .dashboard-placeholder-3 {
    padding: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    width: 500px;
    height: 400px;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.product-color {
  height: 20px;
  width: 20px;
  margin: 0px 5px;
  border-radius: 50%;
}
