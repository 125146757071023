@import "../../globals.scss";

.filter-button {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #00000033;
  border-radius: 5px;
  cursor: pointer;
}

.filter-button:hover {
  border: 1px solid #00000055;
}

.filter-count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 300;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: $white;
  background-color: $blue;
}
