@import "../../globals.scss";

.input-keywords {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: 100%;

  .words {
    display: flex;
    max-width: 100%;
    gap: 5px;
    flex-wrap: wrap;

    .word {
      padding: 2px 6px;
      background-color: $lightBlue;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .input-wrapper {
    @include input-styles;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px;

    input {
      border: none;
      background-color: transparent;
      height: auto;
      padding: 0;
      font-size: inherit;

      &::placeholder {
        color: $placeholder;
      }
    }

    &:focus-within {
      border-color: $focus-color;
      box-shadow: 0 0 0 2px rgba($focus-color, 0.2);
    }
  }

  .real-label {
    @include label-styles;
  }

  &.disabled {
    .input-wrapper {
      color: $black;
      cursor: not-allowed;
      background-color: $disabled;
    }
  }
}
