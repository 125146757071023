@import "../../globals.scss";

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $black;
  gap: 10px;
  font-size: 11;
  padding-bottom: 5px;
  padding-top: 10px;
  flex-wrap: wrap;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  flex-wrap: wrap;
}

.pagi-btn {
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
  color: $blue;
  cursor: pointer;

  &.active {
    background-color: $blue;
    color: $white;
  }
}
