.page_not_found {
  height: calc(var(--vh) * 100);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .heading {
    font-weight: bold;
    font-size: 100px;
  }
}
