@import "../../../../globals.scss";

.track-wrapper-wrapper {
  overflow: auto;
  max-height: 80vh;
  width: 500px;
  position: relative;
  margin-top: -20px;
}

.track-wrapper {
  padding: 20px 40px;

  .circle-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }

  .text-side {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: bold;
      font-size: 15px;
    }

    .des {
      color: $placeholderShown;
      font-size: 14px;
    }
  }

  .circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: $blue;
    position: relative;
  }

  .bar {
    position: absolute;
    flex: 1;
    width: 2px;
    height: 70px;
    background-color: $placeholder;
    margin: 0;
    padding: 0;
    top: 100%;
    left: 47%;
  }
}
