@import "../../../globals.scss";

.air-heading {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.two-infos {
  display: flex;
  gap: 10px;
}

.two-sides {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 900px) {
  .two-infos {
    flex-direction: column;
  }
}

.info-box {
  flex: 1;
  border-radius: 5px;
  padding: 10px;
  background-color: #bcb8b81f;
  display: flex;
  flex-direction: column;
  font-size: small;

  .title {
    color: $blue;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-size: small;
  }
}

.air-form {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.name {
  font-weight: bold;
  font-size: small;
}
.sub-name {
  font-size: small;
  font-weight: normal;
  color: #736e6e;
}
