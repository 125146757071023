$cancelRed: #d22626;
$activeRed: #e02200;
$activeGreen: #76d355;
$blue: #0c3966;
$lightBlue: #dceaff;
$red: #ff4b4b;
$lightRed: #ffdddd;
$yellow: #fe8900;
$lightYellow: #ffede3;
$green: #5aa100;
$lightGreen: #e7ffcf;
$purple: #ae5eff;
$lightPurple: #f4dcff;
$white: #ffffff;
$mainBackground: #c0bfbf33;
$inputBackground: #e7e7e799;
$placeholder: #64646499;
$placeholderShown: #646464;
$black: black;
$veryLightBlue: #e7e7e799;
$veryVeryLightBlue: #e7e7e730;
$multiSelectedBg: #d3e4f1;
$optionFg: #7e7e7e;
$optionHoverBg: #e7e7e7f2;
$gray: #e7e7e7;
$cardMenuBg: #f8f8f8;
$sidebarBtnBorder: #7e7e7e80;
$shadow: #00000040;
$disabled: #cfcfcf;
$darkGray: #333333;
$lightGray: #f0f0f0;

$primaryFont: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

%btn {
  background-color: $blue;
  color: white;
  font-family: $primaryFont;
  font-size: 12px;
  font-weight: bold;
  padding: 9px;
  width: 100px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

* {
  font-family: $primaryFont;
}

$input-height: 40px;
$input-padding: 12px;
$focus-color: $blue;
$hover-color: #f0f0f05e;
$label-color: #4a4a4a;
$label-input-spacing: 3px;
$input-background: $veryLightBlue;

@mixin input-styles {
  height: $input-height;
  padding: $input-padding;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.3s ease;
  background-color: $input-background;
  color: $black;

  &:focus {
    outline: none;
    border-color: $focus-color;
    box-shadow: 0 0 0 2px rgba($focus-color, 0.2);
  }

  // &:hover:not(:focus):not(:disabled):not(.disabled) {
  //   background-color: $hover-color;
  // }
}

@mixin label-styles {
  font-size: 13px;
  font-weight: 600;
  color: $label-color;
  margin-bottom: $label-input-spacing;
  transition: all 0.3s ease;
}
