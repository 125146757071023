@import "../../globals.scss";

.button-component {
  display: inline-block;
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: $blue;
  color: $white;
  font-family: inherit;
  display: flex;
  height: 30px;
  width: max-content;

  &.loading {
    cursor: not-allowed;
    background-color: $placeholderShown;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $placeholderShown;
  }
}
