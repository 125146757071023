@import "../../globals.scss";

.sort-by-wrapper {
  position: relative;

  & .sort-options {
    position: absolute;
    right: 0;
    background-color: $white;
    top: 110%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border: 1px solid #00000033;
    border-radius: 5px;

    & .sort-option {
      padding: 2px 5px;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background-color: #00000011;
      }
    }
  }

  & .sort-by-button {
    display: flex;
    gap: 10px;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #00000033;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      border: 1px solid #00000055;
    }
  }
}
