@import "../../../globals.scss";

.modal-window.product-modal {
  height: 90vh;
  position: relative;

  .modal-form {
    max-height: calc(90vh - 10px);
  }

  .close-btn {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    background-color: #000000f2;
    color: white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.heading-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .center-section {
    font-size: small;
    font-weight: bold;
  }

  .right-section {
    font-size: small;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-right: 10px;
  }

  .btns {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: small;
    font-weight: bold;
    color: $optionFg;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    .icon-btn {
      height: 25px;
      width: 25px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      margin-right: 5px;
    }

    &.actions-btn {
      background-color: $lightYellow;
      color: $yellow;
    }

    &.print-btn {
      background-color: $lightBlue;
      color: $blue;
    }

    &.download-btn {
      background-color: $lightPurple;
      color: $purple;
      margin-right: 15px;
    }
  }
}

.product-info {
  display: flex;
  gap: 10px;
  background-color: #c0bfbf1a;
  padding: 10px;
  border-radius: 5px;
  justify-content: center;

  .image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
      border-radius: 5px;
    }

    .image-section-sub {
      display: flex;
      gap: 10px;

      img:hover {
        border: 1px solid $blue;
      }
    }
  }

  .details-section {
    background-color: #bcb8b812;
    font-size: small;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
    flex: 1;

    .product-color {
      height: 20px;
      width: 20px;
      margin: 0px 5px;
      border-radius: 50%;
    }

    .twoside-details {
      display: flex;
      gap: 30px;
    }
  }
}

.checkbox-section {
  background-color: #bcb8b81f;
  font-size: small;
  padding: 20px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  grid-auto-flow: column;
  gap: 10px;

  .cbox {
    display: flex;
    gap: 10px;
    align-items: center;

    input[type="checkbox"] {
      accent-color: #028917;
    }
  }
}

.two-price-sections {
  display: flex;
  gap: 10px;

  .payment-box {
    background-color: #bcb8b81f;
    border-radius: 5px;
    padding: 10px;
    flex: 1;

    table {
      width: 100%;
    }

    table td {
      padding: 0px;
    }
    td:last-child {
      text-align: right;
      font-weight: bold;
    }
  }
}

.address-box-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.address-box {
  background-color: #bcb8b81f;
  border-radius: 5px;
  padding: 10px;
  flex: 1;
}

.extra-details-section {
  font-size: small;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .extra-box {
    background-color: #bcb8b81f;
    border-radius: 5px;
    padding: 10px;
    flex: 1;

    table {
      width: 100%;
    }

    table td {
      padding: 0px;
    }
    td:first-child {
      font-weight: bold;
    }
    td:last-child {
      text-align: right;
    }
  }
}

.more-details-section {
  background-color: #bcb8b812;
  font-size: small;
  padding: 10px;

  .nav-section {
    display: flex;
    justify-content: space-around;

    .nav-item {
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;

      &.active {
        background-color: #bcb8b826;
        color: $blue;
        text-decoration: underline;
      }
    }
  }
  table {
    padding: 30px;
    padding-bottom: 10px;
  }
  td:first-child {
    font-weight: bold;
    padding-right: 30px;
  }
}

.two-tax-boxs {
  display: flex;
  gap: 10px;
  overflow: hidden;
  flex-wrap: wrap;
}

.tax-box {
  background-color: #bcb8b81f;
  border-radius: 5px;
  padding: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 5px);

  table {
    width: 100%;
  }

  table td {
    padding: 0px;
  }
  td:first-child {
    font-weight: bold;
  }
  td:last-child {
    text-align: right;
  }
}

.utr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 5px;

  .utr-title {
    flex: 1;
    font-weight: bold;
  }

  .utr-body {
    flex: 1;
    background-color: #c0bfbf33;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 250px;
    max-width: 60vw;
    border-radius: 5px;
  }

  .utr-hidden {
    flex: 1;
  }
}

@media only screen and (max-width: 800px) {
  .twoside-details {
    flex-direction: column;
    gap: 10px;
  }
  .address-box-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .heading-section {
    flex-direction: column;
    gap: 10px;
  }

  .product-info {
    flex-direction: column;
  }

  .checkbox-section {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .two-price-sections {
    flex-direction: column;
  }

  .extra-details-section {
    grid-template-columns: 1fr;
  }

  .nav-section {
    flex-direction: column;
  }

  .tax-box {
    flex-basis: calc(100% - 10px);
  }

  .address-box-wrapper {
    grid-template-columns: 1fr;
  }
}
