@import "../../globals.scss";

.view-ticket {
  padding: 1rem;
}

.view-ticket-layout {
  display: flex;
  gap: 10px;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.view-ticket-main-section {
  flex: 1;
  min-width: 0;
}

.vertical-divider {
  width: 1px;
  background-color: #0000004d;

  @media (max-width: 768px) {
    display: none;
  }
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: #0000004d;
}

.view-ticket-sidebar {
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.comment-btn-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.comment-btn {
  background-color: $white;
  color: $black;
  border: 1px solid #666;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 480px) {
    width: 100%;
  }
}

.comment-btn-icon {
  width: 16px;
  height: 16px;
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.comment {
  background-color: rgba($color: $blue, $alpha: 0.05);
  border-radius: 8px;
  padding: 1rem;
  position: relative;

  &:hover {
    .reply-btn {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    padding: 0.75rem;
  }
}

.comment-text-wrapper {
  display: flex;
  gap: 10px;
}

// .comment-text {
//   margin-bottom: 0.5rem;
// }

.comment-date {
  font-size: 0.8rem;
  color: #666;
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.comment-header-text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.comment-header-name {
  font-weight: 600;
  color: $blue;
  font-size: 14px;
}

.comment-header-date {
  font-size: 0.8rem;
  color: #666;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $blue;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}

.sidebar-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  h3 {
    margin: 0;
    font-size: 16px;
  }
}

.notes-content {
  font-size: 12px;
  color: #333;

  p {
    margin: 0;
    word-wrap: break-word;
  }
}

.blue-link {
  color: $blue;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: darken($blue, 10%);
  }
}

.no-comments {
  font-size: 14px;
  color: #666;
  font-style: italic;
}

.custom-fields-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;

  @media (max-width: 480px) {
    font-size: 11px;
  }
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
}

.custom-field {
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
  padding-top: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;

  .delete-custom-field {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;

    &:hover {
      color: #c82333;
    }
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
}

.custom-field-add {
  position: relative;

  .add-custom-field-link {
    color: #007bff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .custom-field-options {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .custom-field-option {
      display: block;
      width: 100%;
      padding: 8px 16px;
      border: none;
      background: none;
      text-align: left;
      cursor: pointer;

      &:hover {
        background: #f8f9fa;
      }
    }
  }
}

.ticket-details-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;

  @media (max-width: 480px) {
    gap: 8px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 2px;

      strong {
        font-size: 11px;
      }
    }
  }
}

.ticket-card-small {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  .ticket-row-one {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
  }

  .ticket-row-one-icon {
    width: 16px;
    height: 16px;
  }

  .ticket-row-two {
    display: flex;
    gap: 10px;
  }

  .ticket-row-two-info {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .ticket-row-two-info-title {
    display: flex;
    flex-direction: row;
    gap: 5px;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 2px;
    }
  }

  .ticket-row-two-info-title-name {
    font-weight: 600;
    color: $blue;
  }

  .ticket-row-two-info-title-report {
    font-size: 12px;
  }

  .ticket-row-two-info-date {
    font-size: 12px;
    color: #666;
  }

  .ticket-row-three {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    margin-left: 10px;
  }
}

.reply-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: $blue;
  cursor: pointer;
  padding: 0;
  opacity: 0;
  transition: opacity 0.2s ease;

  &:hover {
    color: darken($blue, 10%);
  }
}

.comment-popover {
  position: fixed;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;
  min-width: 300px;
  z-index: 1000;

  @media (max-width: 480px) {
    position: fixed;
    left: 10px !important;
    right: 10px;
    bottom: 10px;
    width: auto;
    min-width: 0;
  }
}

.comment-form {
  display: flex;
  gap: 8px;

  @media (max-width: 480px) {
    padding: 10px;
  }
}

.comment-input {
  flex: 1;
  padding: 8px 12px;
  @include input-styles;
  background-color: $input-background;

  &:focus {
    border-color: $blue;
  }
}

.comment-submit {
  background: $blue;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: darken($blue, 10%);
  }
}

.replies-accordion {
  margin-top: 10px;

  summary {
    cursor: pointer;
    color: #666;
    font-size: 0.9em;

    &:hover {
      color: #333;
    }
  }
}

.replies-container {
  margin-top: 10px;
  margin-left: 20px;
}

.reply-comment {
  margin-bottom: 10px;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 4px;
}

.delete-btn {
  border-color: $red !important;
  color: $red !important;

  &:hover {
    background-color: $red !important;
    color: white !important;
  }
}

.editor-input {
  @media (max-width: 480px) {
    min-height: 150px;
  }
}

[class*="modal"] {
  @media (max-width: 768px) {
    width: 90vw !important;
    max-width: none !important;
  }
}

.comment-attachments {
  margin-top: 10px;
  font-size: 12px;

  .attachment-label {
    color: #666;
    margin-bottom: 5px;
  }

  .attachment-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .attachment-link {
    color: $blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
