@import "../../globals.scss";

.ticket-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticket-card {
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
}

.ticket-card-header {
  display: flex;
  gap: 10px;
  padding: 10px;
  transition: background-color 0.2s ease;
  color: $black;
}

.ticket-card-header-in-table {
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
    color: $black;
  }
}

.ticket-card-header-info {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .ticket-card-header-info-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .ticket-card-header-info-title-icon {
    width: 16px;
    height: 16px;
  }

  .ticket-card-header-info-description {
    font-size: 12px;
    display: flex;
    gap: 7px;
    color: #666;
  }

  .ticket-card-header-info-title-id {
    font-weight: 400;
  }
}

.ticket-card-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.ticket-card-body-item {
  flex: 1;
  min-width: 150px;
  padding: 10px;

  @media (max-width: 768px) {
    border-right: none;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }
  }
}

.ticket-card-body-item:last-child {
  border-right: none;
}

.ticket-card-body-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  padding: 5px;
  min-width: 0;

  .item-label {
    font-size: 12px;
    color: #666;
  }

  .item-value {
    font-size: 14px;
    font-weight: 400;
  }

  .dropdown-container {
    position: relative;
    width: 100%;
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0 5px;

    &.editable {
      cursor: pointer;
    }

    span {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;

    input {
      width: 100%;
      padding: 8px;
      border: none;
      border-bottom: 1px solid #e0e0e0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      max-height: 200px;
      overflow-y: auto;

      li {
        padding: 8px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

#heading {
  @media (max-width: 576px) {
    flex-direction: column;
    gap: 10px;

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .ui.input {
        width: 100%;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

.ticket-card-header-info-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;

  .tag {
    background-color: #f0f0f0;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 11px;
    color: #666;
  }
}

.tab-selector {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.tab-item {
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  color: #666;

  &.active {
    color: $blue;
    font-weight: 500;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $blue;
    }
  }

  &:hover {
    color: #000;
  }
}

.deadline-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 5px;

  &.overdue {
    background-color: #ea4335;
  }

  &.active {
    background-color: #34a853;
  }
}
