@import "../../globals.scss";

.upd-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh) * 100);
}

.layout {
  display: flex;
  flex-direction: row;
  height: 450px;
  width: 400px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px $shadow;
  box-shadow: 4px 0px 4px 0px $shadow;

  @media only screen and (max-width: 425px) {
    & {
      width: 100%;
      height: 100%;
    }
  }
}

#segment-upd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 4px 0px $shadow;
  border-radius: 10px;
  background-color: $white;
}

#segment-upd-2 {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
  }
}

.upd-eye-icon {
  position: absolute;
  right: 10px;
  top: 27px;
}

#form-upd {
  width: 80%;
  display: flex;
  flex-direction: column;

  button {
    background-color: $blue;
    padding: 10px;
    font-size: medium;
    width: 90%;
    margin-top: 20px;
    align-self: center;
  }
}
