@import "../../globals.scss";

.actions-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 0px;
  width: fit-content;

  .dot-dot {
    background-color: transparent;
    padding: 5px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }

  .options {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -100;
    opacity: 0;
    white-space: nowrap;
    min-width: 120px;
    background: $white;
    color: $optionFg;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    font-size: small;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    max-height: 220px;
    overflow: auto;

    .spacer {
      height: 30px;
      margin: 0 0 -40px 0;
      background: transparent;
    }
  }

  &.active {
    .dot-dot {
      background-color: $veryLightBlue;
    }

    .options {
      opacity: 1;
      z-index: 100;
    }
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    min-width: 100px;
    font-size: small;
    font-weight: bold;
    color: $optionFg;

    .icon-btn {
      height: 25px;
      width: 25px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      color: $optionFg;
      background-color: $veryLightBlue;
    }

    &:hover {
      .blue-title {
        color: $blue;
      }
      .red-title {
        color: $red;
      }
      .green-title {
        color: $green;
      }
      .yellow-title {
        color: $yellow;
      }
      .purple-title {
        color: $purple;
      }
      .blue-icon {
        color: $blue;
        background-color: $lightBlue;
      }
      .red-icon {
        color: $red;
        background-color: $lightRed;
      }
      .green-icon {
        color: $green;
        background-color: $lightGreen;
      }
      .yellow-icon {
        color: $yellow;
        background-color: $lightYellow;
      }
      .purple-icon {
        color: $purple;
        background-color: $lightPurple;
      }
    }
  }
}
