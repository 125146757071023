@import "../../globals.scss";

.section-card-link {
  text-decoration: none;
}

.card {
  display: flex;
  gap: 10px;
  min-width: 180px;
  width: 100%;
  padding: 10px;
  align-items: center;
  border-radius: 7px;
  font-size: 15px;
  font-weight: bold;
  color: $blue;
  background-color: $veryLightBlue;
  transition: all 0.2s ease;

  .icon-wrapper {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    svg {
      color: $white;
      transition: all 0.2s ease;
      height: 18px;
      width: 18px;
    }
  }

  &:hover {
    color: $white;
    background-color: $blue;

    .icon-wrapper {
      background-color: $white;

      svg {
        color: $blue;
      }
    }
  }
}
