@import "../../globals.scss";

.overlay {
  height: calc(var(--vh) * 100);
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .window {
    background-color: white;
    border-radius: 5px;
    position: relative;
  }

  .close-btn {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 5px;
    margin-top: 5px;
    right: 10px;
    top: 10px;
    background-color: #000000f2;
    color: white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 105;
  }
}
