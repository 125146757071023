@import "../../globals.scss";

.tabs {
  display: flex;
  border-bottom: 1px solid $veryLightBlue;
  gap: 2px;
  flex-wrap: wrap;
  overflow: auto;
}

.tab {
  padding: 5px 7px;
  font-size: 13px;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid $blue;
    color: $blue;

    .tab-count {
      background-color: $blue;
      color: $white;
      padding: 3px 5px;
      font-size: 11px;
      border-radius: 5px;
    }
  }

  &.active:hover {
    border-bottom: 2px solid $blue;
    color: $blue;
  }

  &:hover {
    border-bottom: 2px solid $veryLightBlue;
  }
}

@media only screen and (max-width: 800px) {
  .tab {
    padding: 5px 5px;
  }
}
