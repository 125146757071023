@import "../../globals.scss";

.editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.editor-text-box {
  border-radius: 10px 10px 0px 0px;
  background-color: $veryLightBlue;
  border: 1px solid transparent;
  min-height: 150px;
  padding: 10px;
}

.editor-toolbar {
  border-radius: 5px;
  background-color: $veryLightBlue;
  border: 1px solid transparent;
  border-top: 1px solid $lightBlue;
}

.public-DraftStyleDefault-block {
  margin: 0px;
}
