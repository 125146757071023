@import "../../globals.scss";

.toasts-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  pointer-events: none;
}

.toast {
  border: 2px solid transparent;
  background-color: #e7f5ff;
  border-radius: 4px;
  margin-top: 5px;
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 15px 12px;
  cursor: pointer;
  width: 400px;
  border-radius: 5px;
  gap: 10px;
  animation: 0.3s ease 0s 1 slideInFromRight;
  pointer-events: all;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0);
  }
}

.toast-text-side {
  display: flex;
  flex-direction: column;
  gap: 3px;
  letter-spacing: 0.5px;
  color: #116bc0;

  .toast-heading {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
  }

  .toast-sub-heading {
    font-size: 12px;
  }
}

.toast-success {
  background-color: #ebfbee;

  .toast-text-side,
  .toast_close-btn,
  .toast-progress-bar {
    color: #1f9736;
  }

  .toast-progress-bar {
    background-color: #1f9736;
  }
}

.toast-error {
  background-color: #fff5f5;

  .toast-text-side,
  .toast_close-btn,
  .toast-progress-bar {
    color: #dd1c1c;
  }

  .toast-progress-bar {
    background-color: #dd1c1c;
  }
}

.toast-warning {
  background-color: #fff9db;

  .toast-text-side,
  .toast_close-btn,
  .toast-progress-bar {
    color: #f2950e;
  }

  .toast-progress-bar {
    background-color: #f2950e;
  }
}

.toast-info {
  background-color: #e7f5ff;

  .toast-text-side,
  .toast_close-btn,
  .toast-progress-bar {
    color: #116bc0;
  }

  .toast-progress-bar {
    background-color: #116bc0;
  }
}

.toast_close-btn {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
  color: #116bc0;
}

.toast-progress-bar {
  position: absolute;
  top: -2px;
  left: -1px;
  height: 4px;
  background-color: #116bc0;
  border-radius: 5px;
  animation: 5.5s linear 0s 1 closing;
}

@keyframes closing {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
