@import "../../globals.scss";

.input-mimage {
  display: flex;
  flex-direction: column;
  width: 100px;
  flex-shrink: 0;

  &.disabled {
    .upload-image {
      cursor: not-allowed;

      &:hover {
        border: 2px solid transparent;
      }
    }
  }

  & input {
    display: none;
  }

  & .upload-image {
    position: relative;
    box-sizing: border-box;
    background-color: $veryLightBlue;
    border: 2px solid transparent;
    border-radius: 5px;
    font-size: small;
    min-height: 130px;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;

    .close-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $black;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &.vertical {
      min-height: 130px;
    }

    &:hover {
      box-sizing: border-box;
      border: 2px solid $blue;
    }

    .image {
      flex-grow: 1;
      flex-shrink: 0;
      height: 95px;
      max-width: 80px;
      background-color: $gray;
      padding: 10px;
      border-radius: 10px;
    }

    .file-name {
      max-width: 200px;
      flex-grow: 1;
      flex-shrink: 0;
      height: auto;
      background-color: $gray;
      word-wrap: break-word;
      padding: 20px;
      border-radius: 10px;
      text-align: center;
    }

    .intruction {
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
    }
  }

  &.empty .label {
    color: $placeholderShown;
    font-weight: bold;
    font-size: small;
  }

  & .label {
    color: $black;
    font-weight: bolder;
    font-size: small;
  }
}

.image-add {
  height: 30px;
  width: 30px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.image-name {
  color: $blue;

  &.active {
    text-decoration: underline;
  }
}
