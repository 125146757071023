@import "../../globals.scss";

.timeline-wrapper {
  flex-shrink: 0;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  padding: 3px;
}

.timeline {
  display: flex;
  font-size: small;
}

.bar {
  flex: 1;
  margin-top: 15px;
  width: 30px;
  height: 2px;
  background-color: $lightBlue;

  &.active {
    background-color: $blue;
  }
}

.tl-item {
  display: flex;
  flex-direction: column;
  width: 120px;
  gap: 5px;
  font-weight: bold;
  justify-content: flex-start;

  &.active {
    .step-index {
      background-color: $blue;
      color: $white;
      // box-shadow: 0 0 0 2px $blue;
    }

    .step-name {
      color: $blue;
    }
  }

  .step-index {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: $white;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $lightBlue;
    // box-shadow: 0 0 0 2px $white;
    box-sizing: border-box;
  }

  .step-name {
    color: $placeholderShown;
    margin-right: 10px;
  }
}
