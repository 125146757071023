@import "../../globals.scss";

.sidebar {
  background-color: $white;
  color: $optionFg;
  font-weight: 600;
  width: 80px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  flex-shrink: 0;
  max-height: calc((var(--vh) * 100) - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  --sidebar-width: 80px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .sidebar-btn {
    background-color: transparent;
    border: 1px solid $sidebarBtnBorder;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    float: right;
    cursor: pointer;
    transform: rotate(180deg);
    transition: all 0.3s ease, background-color 0.2s ease;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $lightBlue;
    }
  }

  .sidebar-title:hover {
    cursor: pointer;
  }

  .sidebar-body {
    transition: all 0.3s ease;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    width: 47px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  &.open {
    width: 270px;

    .sidebar-body {
      margin-right: 20px;
      margin-left: 20px;
      width: calc(100% - 40px);
    }

    .sidebar-btn {
      transform: rotate(0deg);
      margin-right: 10px;
    }
  }
}

.sidebar-icon {
  color: inherit;
  background-color: transparent;
  border-radius: 7px;
  border: none;
  margin-right: 10px;

  &.bar-close {
    color: inherit;
    background-color: transparent;
    border-radius: 7px;
    margin-right: 0;
    height: 25px;
    width: 25px;
  }

  &:hover {
    cursor: pointer;
  }
}

.item-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebar-item {
  padding: 6px;
  margin: 2px;
  display: block;
  border-radius: 5px;
  color: $optionFg;
  white-space: nowrap;
  transition: all 0.2s ease;

  &:hover {
    background-color: $lightBlue;
    color: $blue;
    font-weight: bold;
    transform: translateX(5px);
  }

  &.active {
    background-color: $blue;
    color: $white;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: $blue;
      color: $white;
      font-weight: bold;
    }

    &.bar-close {
      background-color: $blue;
      color: $white;
      font-weight: bold;
    }
  }
}

.sidebar-popup {
  font-size: small !important;
  margin-left: 20px !important;
}

@media only screen and (max-width: 800px) {
  .sidebar-popup {
    margin-left: -2000px !important;
  }
}
