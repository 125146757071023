@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
@import "./globals.scss";

html,
body,
*:not(.icon),
.ui:not(.icon),
input,
textarea,
select {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.2px;
  -webkit-tap-highlight-color: transparent;
}

.icon {
  font-family: "Icons" !important;
}

html,
body {
  font-size: 16px;
  line-height: 1.5;
  color: $darkGray;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

.ui {
  font-size: 14px;
}

:root {
  --sidebar-width: 80px;
  --vh: 1vh;
}

#page {
  width: 100%;
  min-height: calc(var(--vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  #main {
    background-color: $mainBackground;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  #content {
    max-height: calc((var(--vh) * 100) - 50px);
    padding: 10px 10px;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: calc(100vw - (var(--sidebar-width)));
    transition: max-width 0.3s ease-in-out;
  }
}

.content-wrapper {
  background-color: $white;
  border-radius: 5px;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.content-wrapper-wrapper {
  background-color: $white;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  overflow: auto;
  max-width: 1200px;
  margin: auto;
}

#heading {
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10px;

  input {
    box-sizing: border-box;
    background-color: $inputBackground;
    border: 1px solid $white;
    border-radius: 5px;
    font-size: small;
    width: 170px;
    padding: 8px 8px;

    &::placeholder {
      color: $placeholder;
    }

    &:hover {
      box-sizing: border-box;
      border: 2px solid $blue;
      padding: 7px 7px;
    }
  }
}

.active-green {
  @extend %btn;
  background-color: $lightGreen;
  color: $green;
  text-align: center;
  padding: 5px;
  cursor: default;
  font-weight: normal;
}

.active-red {
  @extend %btn;
  background-color: $lightRed;
  color: $red;
  text-align: center;
  padding: 5px;
  cursor: default;
  font-weight: normal;
}

.active-blue {
  @extend %btn;
  background-color: $lightBlue;
  color: $blue;
  text-align: center;
  padding: 5px;
  cursor: default;
  font-weight: normal;
}

.right {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ui.scrolling.dropdown .menu,
.ui.dropdown .scrolling.menu {
  max-height: 120px !important;
  overflow: auto !important;
}

.header {
  margin-top: 10px !important;
  color: $black !important;
}

#form-wrapper {
  background-color: $white;
}

#form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
}

#twoside-form {
  display: flex;
  flex-direction: column;
  width: 850px;
  gap: 10px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 10px;
  width: 830px;
  max-width: 100%;
}

@media only screen and (max-width: 800px) {
  #twoside-form {
    width: auto;
  }
  .formside {
    width: auto !important;
  }
  .twoside-form {
    flex-direction: column !important;
    gap: 10px !important;
    max-width: 400px !important;
  }
  .form-grid {
    grid-template-columns: 1fr;
    width: 400px;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

.twoside-form {
  display: flex;
  flex-direction: row;
  gap: 30px;
  max-width: 830px;
}

.formside {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.two-inputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
}

@media only screen and (max-width: 600px) {
  .two-inputs {
    flex-direction: column;
  }
}

.form-switcher {
  display: flex;
  align-items: center;
  gap: 50px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .form-switcher {
    gap: 25px;
  }
}

.form-radio {
  display: flex;
  align-items: center;
  gap: 10px;
  accent-color: $black;

  label {
    margin-top: 4px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.btn {
  @extend %btn;
  width: 120px;

  &:disabled {
    cursor: not-allowed;
    background-color: $placeholderShown;
  }
}

.btn-small {
  @extend %btn;
  width: 100px;
  padding: 5px;

  &:disabled {
    cursor: not-allowed;
    background-color: $placeholderShown;
  }
}

.btn-red {
  @extend %btn;
  width: 120px;
  background-color: $cancelRed;

  &:disabled {
    cursor: not-allowed;
    background-color: $placeholderShown;
  }
}

.btn-out {
  @extend %btn;
  width: 120px;
  background-color: $white;
  color: $blue;
  border: 1px solid $blue;

  &:disabled {
    cursor: not-allowed;
    background-color: $placeholderShown;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  gap: 13px;
  margin: 10px 0px;
  max-width: 100%;
}

/* modal */

.modal-window {
  width: 90vw;
  max-width: 1000px;
  height: auto;
  max-height: 90vh;
  border-radius: 5px;
}

.modal-heading {
  font-size: larger;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  color: $black;
  border-bottom: 1px solid $placeholder;
}

.modal-content {
  padding: 0px;
  margin: 0px;
}

.modal-form {
  height: auto;
  max-height: calc(90vh - 100px);
  padding: 10px 20px;
  overflow: auto;
}

.modal-actions {
  border-top: 1px solid $placeholder;
  padding: 0px 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.small-modal {
  width: 90vw;
  max-width: 500px;
}

.flag-image-wrapper {
  background-color: $lightBlue;
  padding: 7px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.blue-link {
  color: $blue;
  font-size: small;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}

.table-wrapper {
  overflow: auto;
  flex-shrink: 0;
  border-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e9ecef;
}

.ui.table {
  border: none;

  th {
    background-color: #f8f9fa;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  td,
  th {
    padding: 12px 16px;
    border-bottom: 1px solid #e9ecef;
  }

  // tr:hover {
  //   background-color: #f1f3f5;
  // }
}

// .table-body {
//   overflow: auto !important;
// }

.filter-btn {
  border-radius: 5px;
  background-color: $lightBlue;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.button {
  @extend %btn;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 7px 20px;
  width: auto;
}

.i-btn {
  @extend %btn;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
  width: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.acc {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: small;
  background-color: #bcb8b830;
  border-radius: 5px;

  &.open {
    background-color: #bcb8b812;
  }

  .acc-head {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
  }

  .acc-arrow {
    margin-left: auto;
    height: 20px;
    width: 20px;
    background-color: $white;
    padding: 5px;
    border-radius: 50%;
    transform: rotate(-90deg);
  }

  .acc-arrow.open {
    transform: rotate(90deg);
  }

  .acc-body {
    gap: 10px;
    overflow: hidden;
    padding: 0px 10px;
    max-height: 0px;
  }

  .acc-body.open {
    overflow: visible;
    padding: 10px;
    padding-top: 0px;
    max-height: 10000px;
  }
}

.color-blue {
  color: $blue;
}

.bg-blue {
  background-color: $blue;
}

.p-10 {
  padding: 10px;
}

.p-5 {
  padding: 5px;
}

.code {
  background-color: #1d2021;
  padding: 10px;
  border-radius: 5px;
  color: #ebdbb2;
}

.border-blue {
  border: 1px solid $blue;
}

.first-capital::first-letter {
  text-transform: uppercase;
}

.input-label {
  color: $placeholderShown;
  font-weight: bold;
  font-size: small;
}

.tree-hidden-link {
  stroke: transparent !important;
}

.no-perm-wrapper {
  cursor: not-allowed;
  display: flex;
  flex-direction: column;
}

.no-perm {
  filter: opacity(30%);
  cursor: not-allowed;
  pointer-events: none;
}

.no-perm * {
  cursor: not-allowed;
  pointer-events: none;
}

.dashboard-image {
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid #dedede;
}

.image-circle {
  border-radius: 50%;
}

.back-button {
  display: flex;
  align-items: center;
  color: $blue;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  width: fit-content;
  background-color: rgba($blue, 0.1);

  &:hover {
    background-color: rgba($blue, 0.2);
  }

  // .back-button-icon {
  //   margin-right: 8px;
  // }
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba($darkGray, 0.5) transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba($darkGray, 0.5);
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba($darkGray, 0.7);
}

// For Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: rgba($darkGray, 0.5) transparent;
}
