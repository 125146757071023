@import "../../globals.scss";

.sub-tabs {
  display: flex;
  gap: 4px;
  flex-shrink: 0;
  width: 100%;
  flex-grow: 1;
  margin-top: 5px;
}

.sub-tab {
  padding: 3px 7px;
  font-size: 13px;
  cursor: pointer;
  background-color: $gray;
  border-radius: 5px 5px 5px 5px;
  color: $optionFg;
  margin-bottom: 5px;

  &.active {
    color: $blue;
    .tab-count {
      background-color: $blue;
      color: $white;
      padding: 3px 5px;
      font-size: 11px;
      border-radius: 5px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .sub-tab {
    padding: 5px 5px;
  }
}
