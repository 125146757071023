@import "../../globals.scss";

.login-page {
  height: calc(var(--vh) * 100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login {
  padding: 60px 0;

  form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.logo {
  margin-bottom: 15px;
  color: $blue;
}

#segment-dimension {
  display: flex;
  flex-direction: row;
  height: 450px;
  width: 800px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px $shadow;
  box-shadow: 4px 0px 4px 0px $shadow;
}

#segment-dimension2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 45%;
  margin-top: 0;
  margin: 0;
  background-color: $blue;
  color: $white;
  padding: 60px;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 4px 4px 0px $shadow;

  p {
    font-size: small;
  }
}

#women-with-laptop {
  height: 290px;
  width: 240px;
}

#segment-dimension3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 4px 0px $shadow;
  border-radius: 0px 10px 10px 0px;
  background-color: $white;
}

#segment-dimension4 {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
  }
}

#form-dimension {
  width: 80%;
  display: flex;
  flex-direction: column;

  button {
    background-color: $blue;
    font-size: medium;
    height: 35px;
    width: 90%;
    margin-top: 20px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#eye-icon {
  position: absolute;
  right: 10px;
  top: 27px;
}

#segment-dimension2 {
  display: none;
  position: absolute;
}

#segment-dimension3 {
  width: 100%;
  border-radius: 10px;
}

#segment-dimension {
  width: 400px;
}

// @media only screen and (max-width: 1024px) {
//   #segment-dimension2 {
//     display: none;
//     position: absolute;
//   }

//   #segment-dimension3 {
//     width: 100%;
//     border-radius: 10px;
//   }

//   #segment-dimension {
//     width: 400px;
//   }
// }

// @media only screen and (max-width: 425px) {
//   #segment-dimension {
//     width: 100%;
//     height: 100%;
//     border-radius: 0px;
//   }
//   #segment-dimension3 {
//     border-radius: 0px;
//   }
// }
