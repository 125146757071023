@import "../../globals.scss";

.input-text {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: 100%;

  label {
    @include label-styles;
  }

  input {
    @include input-styles;
    background-color: $input-background;
    width: 100%;
    box-sizing: border-box;
    min-width: 100px;

    &::placeholder {
      color: $placeholder;
    }
  }

  &.disabled {
    input {
      background-color: $disabled;
      cursor: not-allowed;
    }
  }
}

.input-text-box {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  label {
    @include label-styles;
  }

  textarea {
    @include input-styles;
    background-color: $input-background;
    width: 100%;
    box-sizing: border-box;
    min-height: 100px;
    resize: vertical;

    &::placeholder {
      color: $placeholder;
    }
  }

  &.disabled {
    textarea {
      background-color: $disabled;
      cursor: not-allowed;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  opacity: 1 !important;
}

.input-check {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: small;
  font-weight: bold;
  color: $placeholderShown;
  accent-color: $blue;

  &.disabled * {
    cursor: not-allowed;
  }
}

.input-image {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.disabled {
    .upload-image {
      cursor: not-allowed;
      background-color: $disabled;

      &:hover {
        border: 2px solid transparent;
      }
    }
  }

  & input {
    display: none;
  }

  &.input-file .upload-image {
    min-height: 7px;
    max-height: fit-content;
  }

  & .upload-image {
    @include input-styles;
    background-color: $input-background;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;

    &.vertical {
      min-height: 100px;
    }

    .image {
      flex-grow: 1;
      flex-shrink: 0;
      height: 90px;
      background-color: $gray;
      padding: 10px;
      border-radius: 10px;
    }

    .file-name {
      max-width: 200px;
      flex-grow: 1;
      flex-shrink: 0;
      height: auto;
      background-color: $gray;
      word-wrap: break-word;
      padding: 0px;
      border-radius: 10px;
      text-align: center;
    }

    .intruction {
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
    }

    .upload-image-wrapper {
      position: relative;

      .close-icon {
        position: absolute;
        right: -5px;
        top: -5px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: $black;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & .label {
    @include label-styles;
  }
}

.toggle-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  height: $input-height;

  &.disabled {
    .toggle-switch {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .toggle-label {
    @include label-styles;
  }

  .toggle-switch {
    --width: 36px;
    --height: 14px;
    --thumb-size: 20px;

    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    margin: 2px;

    & input {
      display: none;
    }

    & .slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--height);
      background-color: rgba(0, 0, 0, 0.38);
      transition: all 0.3s ease-in-out;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: var(--thumb-size);
        height: var(--thumb-size);
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
          0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        transition: all 0.3s ease-in-out;
        transform: translate(0, -50%);
      }
    }

    & input:checked + .slider {
      background-color: rgba(25, 118, 210, 0.5);
      &::before {
        transform: translate(calc(var(--width) - var(--thumb-size)), -50%);
        background-color: #1976d2;
      }
    }

    &:hover .slider::before {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }
}

.select-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  &.disabled .box-selected {
    cursor: not-allowed;
    background-color: $disabled !important;
  }
  &.disabled .option {
    cursor: not-allowed;
  }

  .options-container {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 100;
    margin-bottom: 10px;
    background: $white;
    color: $black;
    max-height: 0;
    width: 100%;
    max-width: 100%;
    opacity: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);

    order: 1;

    &.active {
      max-height: 250px;
      opacity: 1;

      & + .box-selected::after {
        transform: rotateX(180deg);
        top: 7px;
      }
    }

    .options {
      max-height: 120px;
      overflow-y: auto;
    }
  }

  .spacer {
    position: absolute;
    top: 105%;
    width: 100%;
    background-color: transparent;
    z-index: -1;

    &.active {
      min-height: 205px;
    }
  }

  .options-container-multi {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 100;
    margin-bottom: 10px;
    background: $white;
    color: $black;
    max-height: 0;
    width: 100%;
    max-width: 100%;
    opacity: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);

    order: 1;

    &.active {
      max-height: 220px;
      opacity: 1;

      & + .box-selected::after {
        transform: rotateX(180deg);
        top: 7px;
      }
    }

    .options {
      max-height: 120px;
      overflow-y: auto;
    }

    & .options-size {
      max-height: 120px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 600px) {
      & .options-size {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .option,
  .box-selected {
    padding: 7px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: small;
  }

  .option:hover {
    background: $optionHoverBg;
  }

  label:nth-child(1) {
    @include label-styles;
  }

  .option {
    display: flex;
    gap: 10px;
    height: $input-height;
    align-items: center;

    .radio {
      display: none;
    }

    &.hidden {
      display: none;
    }
  }
}

.box-selected {
  width: 100%;
  position: relative;
  min-height: 35px;
  background-color: $input-background;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: small;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  order: 0;
  @include input-styles;
  height: auto;
  min-height: $input-height;
  background-color: $input-background;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    background: url("../../images/arrowBlock.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 20px;
    width: 15px;
    right: 10px;
    top: 8px;
  }

  .box-selected-item {
    font-size: 11px;
    padding: 1px 10px;
    border-radius: 10px;
    background-color: $multiSelectedBg;
  }

  &.placeholder-shown {
    color: $placeholder;
  }
}

.select-all {
  font-size: 12px;
  color: $blue;
  text-align: right;
  margin-right: 10px;
  cursor: pointer;
}

.selected-item {
  padding: 3px 10px;
  border-radius: 50px;
  font-size: 10px;
  font-weight: bold;
  background-color: $blue 26;
}

.search-input {
  input {
    @include input-styles;
    background-color: $input-background !important;
    border-color: $veryLightBlue !important;

    &::placeholder {
      color: $placeholder !important;
    }
  }
}

.input-date {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  input[type="date"]:invalid::-webkit-datetime-edit {
    color: $placeholder;
  }

  label {
    @include label-styles;
  }

  input {
    @include input-styles;
    background-color: $input-background;
    opacity: 1;
    width: 100%;
    box-sizing: border-box;
    margin-top: $label-input-spacing;
  }
  &.disabled {
    input {
      background-color: $disabled;
      color: $black;
    }
  }
}

.fake-input-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: small;
  color: $black;
  background-color: $input-background;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
  cursor: pointer;

  .placeholder {
    color: $placeholder;
  }
}

.error-message {
  font-size: small;
  color: $cancelRed;
}

.input-multi-file {
  .upload-image {
    padding: 15px;
    min-height: auto;
    flex-direction: column;
    gap: 10px;
  }

  .files-list {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 13px;

    .file-name {
      flex: 1;
      margin-right: 10px;
    }

    .remove-file {
      cursor: pointer;
      color: $cancelRed;
      font-size: 12px;
      padding: 2px 6px;

      &:hover {
        background-color: rgba($cancelRed, 0.1);
        border-radius: 50%;
      }
    }
  }

  .instruction {
    color: $blue;
    text-align: center;
    width: 100%;
  }

  &.disabled {
    .upload-image {
      .file-item {
        opacity: 0.7;
      }
    }
  }
}
