@import "../../globals.scss";

#nav {
  width: 100%;
  height: 50px;
  background-color: $blue;
  color: $blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  flex-direction: row;
}

#logo {
  height: 40px;
  width: 100px;
  color: $white;
}

.logout-btn {
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: $blue;
  font-weight: bold;

  .logout-icon {
    margin-right: 10px;
    color: $blue;
  }
}

.nav-user-icon {
  color: $white;
}

.nav-right {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-welcome {
  font-size: 15px;
  color: $white;
  font-weight: 500;
}

.nav-search {
  .ui.search {
    .ui.input {
      input {
        background-color: $mainBackground;
        color: $white;
        border: none;
        border-radius: 5px;
        padding: 8px 16px;
        width: 240px;
        transition: all 0.3s ease;

        &::placeholder {
          color: rgba($white, 0.6);
        }

        &:focus {
          background-color: rgba($white, 0.1);
          box-shadow: 0 0 0 2px rgba($white, 0.2);
        }
      }
    }

    .results.transition.visible {
      background-color: $white;
      border: 1px solid rgba($blue, 0.3);
      max-height: 300px !important;
      overflow-y: auto !important;

      &:not(:empty) {
        .message.empty {
          display: none;
        }
      }

      .message.empty {
        padding: 8px 12px;
        color: rgba($white, 0.8);
      }

      .result {
        padding: 8px 12px;

        &:hover {
          background-color: rgba($white, 0.2);
        }

        .title {
          color: $blue;
        }

        .description {
          color: rgba($blue, 0.8);
        }
      }
    }
  }
}

.ui.search > .ui.icon.input > i.icon {
  color: rgba($white, 0.6);
  right: 5px;
}

.nav-user-avatar {
  width: 32px;
  height: 32px;
  background-color: $white;
  color: $blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.nav-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 800px) {
  #nav {
    padding: 0px 20px;
  }

  .nav-right {
    width: 100%;
  }

  .nav-search {
    flex: 1;
    .ui.search {
      .ui.input {
        input {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  #logo {
    display: none;
  }

  .nav-welcome {
    display: none;
  }

  .nav-search {
    flex: 1;
    .ui.search {
      .ui.input {
        input {
          width: 100%;
        }
      }
    }
  }
}
